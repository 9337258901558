import { FC } from "react";
import { gsap } from "gsap";
import CommonSection from "@components/bussness/CommonSection";
import { getStaticImageUrl } from "@utils/index";
const Mobility: FC = () => {
  return (
    <CommonSection
    titleTop="移动化"
      titleBottom="办公更高效"
      contentTop="随时随地"
      contentBottom="掌握场馆经营情况"
      isMobility
    bottomContent={
        <img alt="" src={getStaticImageUrl('img-screen4-phone.png')} className="w-[4.875rem] sm:w-[9.75rem] h-auto phone sm:pl-10 pl-5 rotate-12" />
    }
    sideImg={getStaticImageUrl('img-screen4.jpg')}
    mainClassname="section_mobility"
    subClassName="area_mobility"
    isReverse
    complete={()=>{
      gsap.to(".phone", {
        duration: 0.3,
        x: 10, // 向右移动
        rotation: 30, // 旋转
        repeat: 3, // 无限重复
        yoyo: true, // 来回运动
        ease: "power1.inOut", // 缓动函数
        scrollTrigger: {
          trigger: ".section_mobility",
          start: "top bottom", // 定义触发动画的位置
          end: "bottom top", // 定义动画结束的位置
          toggleActions: "play none none none",
        },
      })
    }}
  />
  );
};
export default Mobility;
