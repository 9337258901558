import { FC, useState } from "react";
import { getStaticImageUrl } from "@utils/index";
import { gsap } from "gsap";
import { useLocation } from "react-router-dom";
const Footer: FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isMina = searchParams.get('from')=="mina"?true:false; 
  const [isShowQrcode,setIsShowQrcode]=useState<boolean>(false);
   // 处理鼠标进入事件
   const handleMouseEnter = () => {
    setIsShowQrcode(true);
    gsap.to(".footer-qrcode", {
      opacity: 1,
      //transform: "translateX(0)",
      duration: 1,
      ease: "power1.out"
    });
  };

  // 处理鼠标离开事件
  const handleMouseLeave = () => {
    setIsShowQrcode(false);
    gsap.to(".footer-qrcode", {
      opacity: 0,
      //transform: "translateY(100%)",
      duration: 1,
      ease: "power1.in"
    });
  };
  const jumpRed=()=>{
      if(isMina) return;
      window.location.href="https://www.xiaohongshu.com/user/profile/6740287b000000001d02ce9e";
  }
  const jumpSafeWeb=()=>{
    if(isMina) return;
    window.location.href="https://beian.miit.gov.cn/";
    
  }
  return (
    <>
   
    <div className="w-full py-5 sm:h-[80px] pb-safe-offset-24 sm:py-0 flex flex-col sm:flex-row items-center justify-evenly bg-[#272926]">
      <p className="text-sm sm:text-[14px] sm:leading-[20px] text-[#cccccc] flex flex-col justify-center items-center sm:flex-row">
        <span className="pr-4 sm:pr-[16px]">Copyright © 2024 上海拍客云动科技有限公司</span>
        <a target="_blank" className="cursor-pointer pb-6 sm:pb-0" onClick={jumpSafeWeb}>沪ICP备2024052780号-4</a>
      </p>
      <div className="flex flex-row relative">
      <img alt="" src={getStaticImageUrl('connect-wechat-code.png')} className={`${isShowQrcode?'block':'hidden'} sm:w-[152px]  w-[9.5rem] h-auto footer-qrcode opacity-0 absolute bottom-[5rem] sm:bottom-[72px] sm:left-[-60px] left-[-4.25rem]`}/>
        
        <img alt="" src={getStaticImageUrl('connect-wechat.svg')} className="sm:w-[40px] w-10 sm:h-[40px] h-10 sm:mr-[16px] mr-4 cursor-pointer" onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}/>
        <a href="https://v.douyin.com/CeiJ8AStX/" target="_blank"><img alt="" src={getStaticImageUrl('connect-tiktok.svg')} className="sm:w-[40px] w-10 sm:h-[40px] h-10 sm:mr-[16px] mr-4 cursor-pointer"/></a>
        <a  onClick={jumpRed}  target="_blank"><img alt="" src={`${getStaticImageUrl('connect-red.svg')}`} className="sm:w-[40px] w-10 sm:h-[40px] h-10 cursor-pointer" /></a>
      </div>
    </div>
    </>
    
  );
};
export default Footer;
