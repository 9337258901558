import { FC, useEffect } from "react";
import { gsap } from "gsap";
import RotateImg from "@components/bussness/RotateImg";
import { getStaticImageUrl } from "@utils/index";
const Sponsor: FC = () => {
  useEffect(() => {
    const animation =gsap.to('.section_sponsor',{
      onComplete:(()=>{
        gsap.to("#imgs", {
          opacity: 1,
          scale: 1,
          duration: 0.3,
          ease: "bounce.out",
          onComplete: () => {
            gsap.to(".bg", {
              width: "100%",
              ease: "power1.out",
              duration: 0.3,
              scrollTrigger: {
                trigger: ".section_sponsor",
                start: "top 50%", // 定义触发动画的位置
                end: "bottom top", // 定义动画结束的位置
                //markers: true, // 显示标记以便调试
                toggleActions: "play none none none", // 控制动画的播放、暂停等行为
              },
              onComplete: () => {
                gsap.to(".center", {
                  opacity: 1,
                  scale: 1,
                  duration: 0.3,
                  ease: "bounce.out",
                  // scrollTrigger: {
                  //   trigger: ".section_sponsor",
                  //   start: "top bottom", // 定义触发动画的位置
                  //   end: "bottom top", // 定义动画结束的位置
                  //   // markers: true, // 显示标记以便调试
                  //   toggleActions: "play none none none", // 控制动画的播放、暂停等行为
                  // },
                });
              },
            });
          },
          scrollTrigger: {
            trigger: ".section_sponsor",
            start: "top 50%", // 定义触发动画的位置
            end: "bottom top", // 定义动画结束的位置
            // markers: true, // 显示标记以便调试
            toggleActions: "play none none none", // 控制动画的播放、暂停等行为
          },
        });
      })
    })  
  
    return () => {
      animation.kill();
    };
  }, []);
  return (
    <div className="section_sponsor sm:pt-[8rem] pt-[6.5rem]">
      <div className="w-full h-full flex items-end justify-center relative sm:py-20 opacity-0 scale-[0.5] center">
        <img alt="" src={getStaticImageUrl('img-screen6-100pcs.png')} className="sm:w-[25.7%] w-[63.3%]  h-auto absolute" />
        <img alt="" src={getStaticImageUrl('img-screen6-rect.png')} className="sm:w-[36.5%] w-[89.87%] h-auto" />
      </div>
      <div className="w-full flex justify-center items-center mb-20 mt-8 sm:mb-[10.8125rem] sm:mt-0 ">
        <div
          className="grid grid-cols-3 grid-rows-5  sm:grid-cols-5  sm:grid-rows-3 gap-3 sm:gap-5 relative opacity-0 scale-[0.5] w-full px-[6.25%]"
          id="imgs"
        >
          <div className="w-full absolute top-[10%] h-[80%] z-[-1]">
          <div className="w-0 bg-[#12b27d] h-full bg"></div>
          {/* <div className="w-0 bg-[#262729] h-[10%] bg"></div> */}
        </div>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-1.png')} backImg={getStaticImageUrl('logo/LOGO-1-code.png')} idName={'LOGO-1-code'}/>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-2.png')} backImg={getStaticImageUrl('logo/LOGO-2-code.png')} idName={'LOGO-2-code'}/>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-3.png')} backImg={getStaticImageUrl('logo/LOGO-3-code.png')} idName={'LOGO-3-code'}/>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-4.png')} backImg={getStaticImageUrl('logo/LOGO-4-code.png')} idName={'LOGO-4-code'}/>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-5.png')} backImg={getStaticImageUrl('logo/LOGO-5-code.png')} idName={'LOGO-5-code'}/>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-6.png')} backImg={getStaticImageUrl('logo/LOGO-6-code.png')} idName={'LOGO-6-code'}/>
          <span></span>
          <span></span>
          <span></span>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-7.png')} backImg={getStaticImageUrl('logo/LOGO-7-code.png')} idName={'LOGO-7-code'}/>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-8.png')} backImg={getStaticImageUrl('logo/LOGO-8-code.png')} idName={'LOGO-8-code'}/>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-9.png')} backImg={getStaticImageUrl('logo/LOGO-9-code.png')} idName={'LOGO-9-code'}/>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-10.png')} backImg={getStaticImageUrl('logo/LOGO-10-code.png')} idName={'LOGO-10-code'}/>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-11.png')} backImg={getStaticImageUrl('logo/LOGO-11-code.png')} idName={'LOGO-11-code'}/>
          <RotateImg fontImg={getStaticImageUrl('logo/LOGO-12.png')} backImg={getStaticImageUrl('logo/LOGO-12-code.png')} idName={'LOGO-12-code'}/>
          <img
          alt=""
          src={getStaticImageUrl('logo/img-logotitle.png')}
          className="sm:w-[52%] w-[172%] h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 center opacity-0 scale-[0.5]"
        />
        </div>
        
      </div>
    </div>
  );
};
export default Sponsor;
