import {FC, useContext} from "react";
import {gsap} from "gsap";
import CommonSection from "@components/bussness/CommonSection";
import {getStaticImageUrl} from "@utils/index";
import {MyContext} from "../../../App";

const DataAnalysis: FC = () => {
  const isMobile = useContext<Record<string, any>>(MyContext).isMobile;
  return (
    <div className="relative section_data">
      <CommonSection
        titleTop="数据可视化"
        titleBottom="帮助分析决策"
        contentTop="全链路数据追踪"
        contentBottom="经营好坏让数据来说话"
        sideImg={getStaticImageUrl("img-screen3.png")}
        subClassName="area_data"
        complete={() => {
          gsap.to("#mask rect", {
            width: "100%",
            duration: 1,
            ease: "power4.Out",
            scrollTrigger: {
              trigger: ".section_data",
              start: "top bottom", // 定义触发动画的位置
              end: "bottom top", // 定义动画结束的位置
              //markers: true, // 显示标记以便调试
              // toggleActions: "play none none reverse", // 控制动画的播放、暂停等行为
            },
            onComplete: () => {
              gsap.to(".data-analysis-img", {
                opacity: 1,
                scale: 1,
                duration: 0.5,
                ease: "power1.out",
                scrollTrigger: {
                  trigger: `.section_data`,
                  start: "top bottom",
                  end: "bottom top",
                  //markers: true,
                  // toggleActions: "play none none reverse",
                  //scrub: true,
                },
              });
            },
          });
        }}
        tagesContent={
          <div>
            <img
              alt=""
              src={getStaticImageUrl("tag-data-4.png")}
              className="w-[7.75rem] sm:w-[19.4%] h-auto absolute left-[29%] top-0 sm:left-[29%] sm:top-[19%] opacity-0 scale-[0.5] data-analysis-img"
            />
            <img
              alt=""
              src={getStaticImageUrl("tag-data-1.png")}
              className="w-[6.75rem] sm:w-[16.7%] h-auto absolute left-[5%] top-[30%] sm:left-[16%] sm:top-[37.1%] opacity-0 scale-[0.5] data-analysis-img"
            />
            <img
              alt=""
              src={getStaticImageUrl("tag-data-3.png")}
              className="w-[5.75rem] sm:w-[14.4%] h-auto absolute left-[45%] top-[50%] opacity-0 scale-[0.5] data-analysis-img"
            />
            <img
              alt=""
              src={getStaticImageUrl("tag-data-2.png")}
              className="w-[6.75rem] sm:w-[16.7%] h-auto absolute left-[30%] top-[73%] sm:left-[39%] sm:top-[67%] opacity-0 scale-[0.5] data-analysis-img"
            />
          </div>
        }
      />
      <svg
        width={isMobile ? "166.67%" : "62.5%"}
        className="absolute bottom-[-23%] left-[-116%]  sm:left-[0]"
        viewBox="0 0 1300 716"
      >
        {/* 在defs元素中定义的图形元素不会直接呈现。你可以在你的视口的任意地方利用 <use>元素呈现这些元素 */}
        <defs>
          {/* 用来引用一个遮罩元素 */}
          <mask id="mask" x="0" y="0" width="100%" viewBox="0 0 1300 716">
            {/* rect元素是 SVG 的一个基本形状，用来创建矩形，基于一个角位置以及它的宽和高。它还可以用来创建圆角矩形。 */}
            <rect
              x="0"
              y="0"
              width="0"
              height="100%"
              fill="#FFF"
              viewBox="0 0 1300 716"
            />
          </mask>
        </defs>
        <svg
          mask="url(#mask)"
          width="1300"
          height="716"
          viewBox="0 0 1300 716"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1160_330)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1200.04 49.69C1198.48 48.9343 1198.55 46.6915 1200.15 46.0385L1286.97 10.6684C1288.46 10.0603 1290.03 11.3772 1289.68 12.9513L1269.93 102.489C1269.57 104.157 1267.41 104.626 1266.38 103.263L1246.13 76.3953C1245.32 75.3203 1243.7 75.3368 1242.91 76.4282L1118.44 248.752C1118.01 249.354 1117.27 249.666 1116.54 249.561L939.737 224.321C939.108 224.232 938.475 224.446 938.029 224.898L788.537 376.686C788.191 377.037 787.729 377.248 787.237 377.279L518.317 394.18C517.793 394.213 517.303 394.45 516.953 394.841L238.596 705.566C238.216 705.989 237.675 706.231 237.106 706.231L12.2896 706.231C10.4469 706.231 9.58467 703.951 10.9665 702.732L439.45 324.716C439.805 324.403 440.259 324.226 440.732 324.216L760.331 317.745C760.765 317.736 761.185 317.586 761.526 317.318L928.299 186.282C928.745 185.931 929.319 185.787 929.877 185.884L1108.56 216.917C1109.28 217.043 1110.02 216.761 1110.47 216.183L1228.46 66.1829C1229.25 65.1798 1228.91 63.7042 1227.77 63.147L1200.04 49.69Z"
              fill="#12B27D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1196.38 36.7775L1283.2 1.40742C1292.15 -2.24142 1301.53 5.66035 1299.44 15.1046L1279.7 104.643C1277.49 114.65 1264.57 117.465 1258.4 109.282L1244.67 91.0714L1126.55 254.607C1123.94 258.221 1119.54 260.09 1115.12 259.46L942.321 234.792L795.662 383.703C793.589 385.808 790.813 387.074 787.865 387.259L522.215 403.954L246.045 712.238C243.768 714.779 240.518 716.231 237.107 716.231L12.2903 716.231C1.23403 716.231 -3.93949 702.547 4.35151 695.233L432.835 317.217C434.964 315.339 437.691 314.276 440.531 314.218L757.453 307.801L922.122 178.418C924.796 176.317 928.238 175.449 931.589 176.031L1105.56 206.247L1214.46 67.807L1195.67 58.6864C1186.33 54.1523 1186.76 40.6958 1196.38 36.7775ZM1242.91 76.4281C1243.7 75.3367 1245.32 75.3202 1246.13 76.3952L1250.79 82.5808L1250.8 82.5878L1266.39 103.263C1267.41 104.626 1269.57 104.157 1269.94 102.489L1289.68 12.9512C1290.03 11.3772 1288.46 10.0602 1286.97 10.6683L1200.15 46.0384C1198.55 46.6914 1198.48 48.9342 1200.04 49.6899L1227.77 63.147C1228.91 63.7042 1229.25 65.1797 1228.46 66.1828L1110.47 216.183C1110.02 216.761 1109.28 217.042 1108.56 216.917L929.878 185.884C929.319 185.787 928.746 185.931 928.3 186.282L761.527 317.318C761.186 317.586 760.766 317.736 760.332 317.745L440.733 324.216C440.26 324.226 439.805 324.403 439.45 324.716L10.9672 702.732C9.58533 703.951 10.4476 706.231 12.2903 706.231L237.107 706.231C237.675 706.231 238.217 705.989 238.596 705.566L516.953 394.841C517.304 394.45 517.794 394.213 518.318 394.18L787.238 377.279C787.729 377.248 788.192 377.037 788.537 376.686L938.03 224.898C938.475 224.446 939.109 224.232 939.737 224.321L1116.54 249.56C1117.27 249.665 1118.01 249.354 1118.44 248.752L1242.91 76.4281Z"
              fill="#272926"
            />
          </g>
          <defs>
            <clipPath id="clip0_1160_330">
              <rect width="1300" height="716" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </svg>
    </div>
  );
};
export default DataAnalysis;
