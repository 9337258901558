import { FC, useEffect } from "react";
import { gsap } from "gsap";
import { getStaticImageUrl, transX } from "@utils/index";

const Activity: FC = () => {
  useEffect(() => {
    const scrollTriggers = gsap.utils
      .toArray(`.area_activity`)
      .map((trigger: any) => {
        return gsap.to(trigger, {
          onComplete: () => {
            gsap.to(trigger, {
              opacity: 1,
              transform: "translateX(0)",
              scale: 1,
              duration: 0.5,
              ease: "power1.out",
              scrollTrigger: {
                trigger: `.section_activity`,
                start: "top 50%",
                end: "bottom top",
                //markers: true,
                toggleActions: "play none none none",
                //scrub: true,
              },
              onComplete: () => {
                gsap.to(".section_mobile2", {
                  duration: 0.3,
                  opacity: 1,
                  x: transX(200), // 滚动到的水平位置
                  ease: "cubic-bezier(0.4, 0.2, 0.6, 0.8)",
                  // scrollTrigger: {
                  //   trigger: ".section_activity",
                  //   start: "top bottom", // 定义触发动画的位置
                  //   end: "bottom top", // 定义动画结束的位置
                  //   //markers: true, // 显示标记以便调试
                  //   toggleActions: "play none none none", // 控制动画的播放、暂停等行为
                  // },
                });
              }
            });
          },
        });
      });
    // 清理函数，组件卸载时销毁动画
    return () => {
      scrollTriggers.forEach((scrollTrigger) => scrollTrigger.kill());
    };
  }, []);
  return (
    <div className="relative">
     <div
      className={`flex h-full flex-col sm:flex-row w-full sm:py-[3.75rem] py-10 sm:pl-[10rem] section_activity`}
    >
      <div
        className={`flex justify-start sm:items-start sm:ml-0 ml-12 items-center flex-1  opacity-0 scale-[0.5] relative -translate-x-full area_activity`}
      >
        <div className="sm:pt-[9.375rem]">
          <p className="text-[2.5rem] sm:text-[5rem] sm:leading-[6rem] leading-[3rem]  text-[#272926] font-bold">
            专属微信小程序
          </p>
          <p className="text-[2.5rem] sm:text-[5rem] sm:leading-[6rem] leading-[3rem]  text-[#272926] font-bold sm:pb-6 pb-3">
            助力私域运营
          </p>
          <p className="text-base sm:text-[2rem] sm:leading-[3rem] text-[#272926]">
            拉新引流、私域裂变
          </p>
          <p className="text-base pb-10 sm:pb-0 sm:text-[2rem] sm:leading-[3rem] text-[#272926]">
            你的小程序就是你的营销窗口
          </p>
        </div>
       
      </div>
    
      <div
        className={`w-full sm:w-[67.6%] opacity-0 scale-[0.5] relative translate-x-full area_activity flex`}
      >
        <img
          src={getStaticImageUrl("img-mockup-1.png")}
          className="w-[34%] sm:ml-[30%] ml-[25%] h-auto"
          alt=""
        />
        <img
          src={getStaticImageUrl("img-mockup-2.png")}
          className="w-[30%] h-auto absolute bottom-2.5 sm:bottom-5  left-[33%] z-[-1] section_mobile2 opacity-0"
          alt=""
        />
        <img
          src={getStaticImageUrl("img-screen1-bg.png")}
          alt=""
          className="w-full h-auto translate-x-[10%] absolute top-[20%] z-[-2]"
        />
      </div>
    </div>
      <img
          alt=""
          src={getStaticImageUrl("gif/animate-ball.gif")}
          className="sm:w-[55.625rem] max-w-[150%]  w-[27.8125rem] h-auto absolute sm:left-[-5.125rem] sm:top-[-3.25rem] left-[-5rem] top-[-6.5rem] area_activity opacity-0"
        />
    </div>
   
  );
};
export default Activity;
