import {FC} from "react";
import CommonSection from "@components/bussness/CommonSection";
import {getStaticImageUrl} from "@utils/index";
import {gsap} from "gsap";

const Intelligent: FC = () => {
  return (
    <CommonSection
      titleTop="智能化"
      titleBottom="无人也能管"
      contentTop="开灯、开门、场地监控"
      contentBottom="一套系统全搞定"
      topContent={
        <img
          alt=""
          src={getStaticImageUrl("img-AI.png")}
          className="intelligent_ball w-[4.56rem] sm:w-[9.125rem] h-auto mb-8 sm:mb-16 opacity-0"
        />
      }
      sideImg={getStaticImageUrl("img-screen5.jpg")}
      mainClassname="section_intelligent"
      subClassName="area_intelligent"
      complete={
        ()=>gsap.to(".section_intelligent", {
          onComplete:()=>{
            gsap.fromTo(".intelligent_ball",{
              opacity:0,
              x:'45rem'
            }, {
              x: 0,
              rotation: -360,
              duration: 0.5,
              opacity: 1,
              ease: "power1.out",
              scrollTrigger: {
                trigger: ".section_intelligent",
                start: "top bottom", // 定义触发动画的位置
                end: "bottom top", // 定义动画结束的位置
                //toggleActions: "play none none reverse",
              },
              onComplete: () => {
                gsap.to(".intel-img", {
                  scale: 1,
                  duration: 1,
                  opacity: 1,
                  ease: "easeIn",
                  scrollTrigger: {
                    trigger: ".section_intelligent",
                    start: "top bottom", // 定义触发动画的位置
                    end: "bottom top", // 定义动画结束的位置
                  //  toggleActions: "play none none reverse",
                  },
                });
              },
            })
          }
        })
      
      }
      tagesContent={
        <div>
          <img
            alt=""
            src={getStaticImageUrl("tag-AI-2.png")}
            className="w-[36%] sm:w-[22%] h-auto absolute top-[-3%] left-[50%] sm:left-[47%] sm:top-[12.7%] opacity-0 scale-[0.5] intel-img"
          />
          <img
            alt=""
            src={getStaticImageUrl("tag-AI-1.png")}
            className="w-[38.97%] sm:w-[23.78%] h-auto absolute left-[18%] top-[20%]  sm:left-[27%] sm:top-[28%] opacity-0 scale-[0.5] intel-img"
          />
          <img
            alt=""
            src={getStaticImageUrl("tag-AI-4.png")}
            className="w-[30.76%] sm:w-[18.78%] h-auto absolute left-[50%] top-[53%] sm:left-[46%] sm:top-[46.78%] opacity-0 scale-[0.5] intel-img"
          />
          <img
            alt=""
            src={getStaticImageUrl("tag-AI-3.png")}
            className="w-[30.76%] sm:w-[18.78%] h-auto absolute left-[15%] top-[71%] sm:left-[25%] sm:top-[57.5%] opacity-0 scale-[0.5] intel-img"
          />
          <img
            alt=""
            src={getStaticImageUrl("tag-AI-5.png")}
            className="w-[30.76%] sm:w-[18.78%] h-auto absolute left-[60%] bottom-[-3%] sm:left-[53%] sm:top-[68.2%] opacity-0 scale-[0.5] intel-img"
          />
        </div>
      }
    />
  );
};
export default Intelligent;
