import {FC, MouseEvent, useContext, useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {throttle} from "lodash";
import {getStaticFileUrl, getStaticImageUrl, isWeChatBrowser,} from "@utils/index";
import {useLocation} from 'react-router-dom';
import {MyContext} from "../../../App";

const Header: FC = () => {
  const [isShowNav, setIsShowNav] = useState<boolean>(false);
  const [isChangePhone, setIsChangePhone] = useState<boolean>(false);
  const [isChangeTop, setIsChangeTop] = useState<boolean>(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isMina = searchParams.get('from')=="mina"?true:false;
  const isMobile = useContext<Record<string, any>>(MyContext).isMobile;
  //const isBigPC=useContext<Record<string, any>>(MyContext).isBigPC;
  const videoRef = useRef<any>(null);
  const width=window.innerWidth;
  const height=711*width/390;
  const [isPlaying, setIsPlaying] = useState(false);
  const url = isMobile
    ? getStaticFileUrl("mobile.mp4")
    : getStaticFileUrl("web.mp4");
  const isWeChat = isWeChatBrowser();
  useEffect(() => {
    let flag = true;
    const handleScroll = throttle(() => {
      if (isMobile) {
        return;
      }
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > window.innerHeight / 2) {
        setIsShowNav(true);
        // if(!isShowNav)
        //   gsap.to(".navbar", {
        //     opacity: 1,
        //     duration: 0.5,
        //     ease: "power1.out",
        //   });

      } else {
        setIsShowNav(false);
        // if(!isShowNav)
        // {
        //   gsap.to(".navbar", {
        //     opacity: 0,
        //     duration: 0.5,
        //     ease: "power1.out",
        //     onComplete:()=>{
        //       setIsShowNav(false);
        //     }
        //   });
        //  }
      }
    }, 100);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMobile]);

  // 处理鼠标进入事件
  const handleMouseEnter = () => {
    setIsChangePhone(true);
    gsap.to(".customServer", {
      opacity: 1,
      x:-72,
      duration: 0.3,
      ease: "power1.out",
    });
  };

  // 处理鼠标离开事件
  const handleMouseLeave = () => {
    setIsChangePhone(false);
    gsap.to(".customServer", {
      opacity: 0,
      x:0,
      duration: 0.3,
      ease: "power1.in",
    });
  };
  const handleMouseEnterBottom = () => {
    setIsChangeTop(true);
  };
  const handleMouseLeaveBottom = () => {
    setIsChangeTop(false);
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleScroll = (e: MouseEvent<HTMLElement, MouseEvent>, id: string) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
    const onPress=()=>{
      if(!(isWeChat&&isMobile)) return;
      gsap.to('.play-img', {
          opacity: 0,
          duration: 0.5,
          ease: "power1.out",
          onComplete:()=>{
              videoRef&&videoRef.current.play();
              setIsPlaying(true);
          }
        });
    }
  return (
    <div>
      <div className={`bg-[#272926] p-[20px] rounded-[24px] fixed bottom-[160px] right-[24px] z-[999] opacity-0  customServer ${isChangePhone?'flex':'hidden'}`}>
        <img
          alt=""
          src={getStaticImageUrl("img-QRcode.png")}
          className="w-[80px] h-[80px] mr-[12px]"
        />
        <div className="flex flex-col items-start justify-center">
          <span className="text-[14px] leading-[20px] text-[#FFF]">7x24小时咨询热线</span>
          <span className="text-[30px] leading-[36px] text-[#FFF]">400-008-5339</span>
        </div>
      </div>
      {/* 客服按钮 */}
      <div
        className={`w-[60px] h-[118px] flex items-center justify-center rounded-full bg-[#272926] flex-col fixed bottom-[160px] right-[24px] z-[999] navbar ${
          isShowNav ? "block" : "hidden"
        }`}
      >
        <button
          className="w-[56px] h-[56px] p-[2px] flex items-center justify-center flex-col rounded-full hover:bg-[#FFF]"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            alt=""
            src={`${
              isChangePhone
                ? getStaticImageUrl("icon-Phone-black.svg")
                : getStaticImageUrl("icon-Phone.svg")
            }`}
            className="w-[24px] h-[24px]"
          />
          <span
            className={`text-[14px] leading-[20px] ${
              isChangePhone ? "text-[#272926]" : "text-[#12b27d]"
            }`}
          >
            客服
          </span>
        </button>
        <button
          className="w-[56px] h-[56px] p-[2px] m-[2px] flex items-center justify-center flex-col rounded-full hover:bg-[#FFF]"
          onClick={scrollToTop}
          onMouseEnter={handleMouseEnterBottom}
          onMouseLeave={handleMouseLeaveBottom}
        >
          <img
            alt=""
            src={`${
              isChangeTop
                ? getStaticImageUrl("icon-Pin-black.svg")
                : getStaticImageUrl("icon-Pin.svg")
            }`}
            className="w-[24px] h-[24px]"
          />
          <span
            className={`text-[14px] leading-[20px] ${
              isChangeTop ? "text-[#272926]" : "text-[#12b27d]"
            }`}
          >
            顶部
          </span>
        </button>
      </div>
      <div
        className={`hidden sm:block leading-[80px] bg-transparent absolute top-[16px] left-[160px] z-[999] ${
          isShowNav ? "hidden" : "block"
        }`}
      >
          <img
            alt=""
            src={getStaticImageUrl("logo-easyclub-white.svg")}
            className="w-auto h-[44px]"
          />
      
      </div>
      {/* 头部信息 */}
      <div
        className={`h-[80px] bg-[#272926] w-full fixed top-0 left-0 flex justify-end items-center navbar z-[999] px-[160px] ${
          isShowNav ? "block" : "hidden"
        }`}
      >
        <div className="flex flex-row justify-between items-center w-full">
          <img
            alt=""
            src={getStaticImageUrl("logo-easyclub-white.svg")}
            className="h-[44px] w-auto"
          />
          <div className="flex flex-col sm:flex-row gap-[24px]">
            <button
              className="bg-[#FFF] w-[128px] h-[44px] text-[#272926] font-[630] rounded-full hover:bg-[#E5E5E5] active:bg-[#CCCCCC] text-[16px] leading-[24px]"
              onClick={(e: any) => handleScroll(e, "section_service")}
            >
              联系我们
            </button>
            <a
              target="_blank"
              className="bg-[#12B27D] w-[128px] h-[44px] flex items-center justify-center text-[#FFFFFF] text-[16px] leading-[24px] font-[630] rounded-full hover:bg-[#10A071] active:bg-[#0E8E64]"
              href="https://b.aitennis.cc/"
            >
              进入易馆云
            </a>
          </div>
        </div>
      </div>
      {/* 移动端顶部块 */}
      <div
        className={`w-full absolute top-0 left-0 flex justify-start items-center z-[999] sm:hidden`}
      >
       <img alt="" src={getStaticImageUrl('resp/logo-cube-white.svg')} className="pl-6 pt-4"/>
      </div>
      {/* 移动端底部按钮 */}
      <div
        className={`pb-safe bg-[#272629] w-full fixed bottom-0 left-0 flex justify-center items-center z-[999] sm:hidden`}
      >
        <div className="flex gap-3 py-3">
          <button
            className={`bg-[#FFF] ${isMina?'w-[16rem]':'w-[10.3125rem]'} h-11 text-[#272926] font-bold rounded-full hover:bg-[#E5E5E5] active:bg-[#CCCCCC] text-base`}
            onClick={(e: any) => handleScroll(e, "section_service")}
          >
            联系我们
          </button>
          {/* <WeChatMiniProgramButton/> */}
          <a
          target="_blank"
            className={`bg-[#12B27D] w-[10.3125rem] h-11 font-bold flex items-center justify-center text-[#FFFFFF] text-base rounded-full hover:bg-[#10A071] active:bg-[#0E8E64] ${isMina?'hidden':''}`}
            href="weixin://dl/business/?appid=wxdaa37cb8d5daaaa8&path=pages/tabbar/workbench/index"
          >
            进入易馆云
          </a>
        </div>
      </div>
      <div className={`relative overflow-hidden`} onClick={onPress}>
        {isWeChat&&isMobile ? (
          <div className={`w-full z-[100]`} style={{height:`${height}px`}}>
          <video
            ref={videoRef}
            src={getStaticFileUrl('mobile.mp4')}
            muted
            playsInline
            autoPlay
            loop
            className={`w-full h-auto ${isPlaying?'block':'hidden'}`}
          />
          {!isPlaying && (
            <div
             className='w-full h-full relative opacity-1 play-img'
            >
               <img
                src={getStaticImageUrl('mobile-bg.jpeg')}
                alt=""
                className='w-full h-auto'
                />  
              {/* 播放按钮 */}
              <img
                src={getStaticImageUrl('icon-play.svg')}
                alt="播放按钮"
                className='w-12 h-auto pt-20 z-100'
                style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
              />
            </div>
          )}
        </div>
        ) : (
          <video
            src={url}
            controls={false}
            playsInline //对于支持 inline 播放的视频，不允许视频播放时自动进入全屏模式。
            autoPlay // 自动播放
            muted // 静音播放
            loop // 循环播放
            preload="auto"
            className={`object-cover w-screen h-screen`}
          >
            Your browser does not support the video tag.
          </video>
        )}

        <div className="absolute  left-0 top-0 bg-transparent w-full h-full flex items-center justify-center flex-col">
          <p className="sm:text-[7.5rem] text-[3.125rem] font-[700] text-[#FFF] sm:mb-6 mb-0 mt-[-7.5rem]">
            易馆云
          </p>
          <p className="sm:text-[2rem] sm:leading-[3rem] text-lg text-[#FFF] text-center mb-20">
            网球俱乐部全栈营销管理工具
          </p>
          <div className="hidden sm:flex flex-col sm:flex-row sm:gap-6 gap-3 sm:static absolute bottom-10">
            <a
              target="_blank"
              className="bg-[#12B27D] w-[19.375rem] h-[3.25rem] sm:w-72 sm:h-16 flex items-center justify-center text-[#FFFFFF] text-2xl font-medium rounded-full hover:bg-[#10A071] active:bg-[#0E8E64]"
              href="https://b.aitennis.cc/"
            >
              进入易馆云
            </a>
            <button
              className="bg-[#FFFFFF] w-[19.375rem] h-[3.25rem] sm:w-72 sm:h-16 text-[#272926] text-2xl font-medium rounded-full hover:bg-[#E5E5E5] active:bg-[#CCCCCC]"
              onClick={(e: any) => handleScroll(e, "section_service")}
            >
              联系我们
            </button>
          </div>
        </div>

        <div className="w-full absolute bottom-10 left-0 hidden sm:block">
          <img
            alt=""
            src={getStaticImageUrl("icon-arrow.svg")}
            className="w-12 h-12 mx-auto"
          />
        </div>
      </div>
    </div>
  );
};
export default Header;

