import {FC} from "react";
import Header from "./Header";
import Activity from "./Activity";
import Management from "./Management";
import DataAnalysis from "./DataAnalysis";
import Mobility from "./Mobility";
import Intelligent from "./Intelligent";
import Sponsor from "./Sponsor";
import Footer from "./Footer";
import CustomService from "./CustomService";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const Home: FC = () => {
  return (
    <div className="overflow-hidden">
      <Header/>
      <Activity/>
      <Management/>
      <DataAnalysis/>
      <Mobility/>
      <Intelligent/>
      <Sponsor/>
      <CustomService/>
      <Footer/>
    </div>
  );
};
export default Home;
