import {FC, useContext, useEffect} from "react";
import {gsap} from "gsap";
import {getStaticImageUrl} from "@utils/index";
import {useLocation} from "react-router-dom";
import {MyContext} from "../../../App";

const CustomService: FC = () => {

  const isMobile = useContext<Record<string, any>>(MyContext).isMobile;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isMina = searchParams.get('from') === "mina";
  useEffect(() => {
    const animation = gsap.to(".section_service", {
      onComplete: () => {
        gsap.to(".img1", {
          opacity: 1,
          scale: 1,
          duration: 0.3,
          ease: "power1.out",
          onComplete: () => {
            gsap.to(".img2", {
              opacity: 1,
              scale: 1,
              duration: 0.5,
              ease: "bounce.out",
              scrollTrigger: {
                trigger: ".section_service",
                start: "top 50%", // 定义触发动画的位置
                end: "bottom top", // 定义动画结束的位置
                //markers: true, // 显示标记以便调试
                toggleActions: "play none none none", // 控制动画的播放、暂停等行为
              },
            });
          },
          scrollTrigger: {
            trigger: ".section_service",
            start: "top 50%", // 定义触发动画的位置
            end: "bottom top", // 定义动画结束的位置
            //markers: true, // 显示标记以便调试
            toggleActions: "play none none none", // 控制动画的播放、暂停等行为
          },
        });
      },
    });

    return () => {
      animation.kill();
    };
  }, []);
  const addWeChat=()=>{
      if(isMina) return;
      window.location.href="https://work.weixin.qq.com/kfid/kfc3ed315270d3d8d05";
  }
  return (
    <div
      className="flex items-center justify-center flex-col pb-[3.75rem] sm:pb-40 section_service relative"
      id="section_service"
    >
      <img
        alt=""
        src={getStaticImageUrl("img-screen7-title.png")}
        className="w-[89%] sm:w-[31.7%] h-auto mb-[-5%] z-20 opacity-0 scale-[0.5] img2"
      />
      {isMobile ? (
        <img
          alt=""
          src={getStaticImageUrl("resp/img-screen7-resp.png")}
          className="w-[93.85%] h-auto mt-[1/2] z-10 opacity-0 scale-[0.5] img1"
        />
      ) : (
        <img
          alt=""
          src={getStaticImageUrl("img-screen7.png")}
          className="w-[81.25%] h-auto mt-[1/2] z-10 opacity-0 scale-[0.5] img1"
        />
      )}
      {isMobile ? (
        <img
          alt=""
          src={getStaticImageUrl("resp/img-screen7-bg-resp.png")}
          className="w-[100%] h-auto mt-[-95%] opacity-0 scale-[0.5] img1"
        />
      ) : (
        <img
          alt=""
          src={getStaticImageUrl("img-screen7-bg.png")}
          className="w-[78.5%] h-auto mt-[-35%] ml-[-15%] opacity-0 scale-[0.5] img1"
        />
      )}
      {isMobile ? (
        <a onClick={addWeChat} target="_blank">
          <img
          alt=""
          src={getStaticImageUrl("resp/btn-1.png")}
          className="w-[14.625rem] h-auto  absolute left-[3%] bottom-[26%]"
        />
        </a>
        
      ) : (
        ""
      )}
      {isMobile ? (<a href="tel:400-008-5339">
        <img
          alt=""
          src={getStaticImageUrl("resp/btn-2.png")}
          className="w-[14.625rem] h-auto  absolute left-[3%] bottom-[17.6%]"
        />
      </a>
       
      ) : (
        ""
      )}
    </div>
  );
};
export default CustomService;
