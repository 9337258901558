import { FC, useEffect } from "react";
import { gsap } from "gsap";
export interface SectionProps {
  titleTop?: string;
  titleBottom?: string;
  topContent?: JSX.Element | string;
  bottomContent?: JSX.Element | string;
  sideImg?: string;
  mainClassname?: string;
  subClassName?: string;
  isReverse?: boolean;
  tagesContent?: JSX.Element | string;
  contentTop?: string;
  contentBottom?: string;
  rightContent?: JSX.Element | string;
  isMobility?:boolean;
  complete?:Function
}
const CommonSection: FC<SectionProps> = ({ ...props }) => {
  useEffect(() => {
    // 监听滚动条并创建动画效果
    // trigger: 指定触发动画的元素或选择器。
    // start: 定义动画开始的滚动位置，可以是像 "top bottom"、"bottom top"、"center center" 这样的字符串，或者一个数字指定的偏移量。
    // end: 定义动画结束的滚动位置，与 start 类似。
    // scrub: 控制动画是否应该随着滚动的进度而平滑地播放（类似于缓动效果）。
    // toggleActions: 定义当触发器进入和退出视口时动画的行为，如 "play none none reverse"。
    // markers: 在页面上显示动画的开始和结束标记，便于调试。
    //gsap.registerPlugin(ScrollTrigger);
    // 初始化 scrollTrigger

    const scrollTriggers = gsap.utils
      .toArray(`.${props.subClassName}`)
      .map((trigger: any) => {
        return gsap.to(trigger, {
          onComplete: () => {
            gsap.to(trigger, {
              opacity: 1,
              transform: "translateX(0)",
              scale: 1,
              duration: 0.5,
              ease: "power1.out",
              scrollTrigger: {
                trigger: `.${props.mainClassname}`,
                start: "top 50%",
                end: "bottom top",
               // markers: true,
                toggleActions: "play none none none",
                //scrub: true,
              },
              onComplete:()=>{props.complete&&props.complete()}
            });
          },
        });
      });
    // 清理函数，组件卸载时销毁动画
    return () => {
      scrollTriggers.forEach((scrollTrigger) => scrollTrigger.kill());
    };
  }, []);
  return (
    <div
      className={`flex h-full flex-col sm:flex-row w-full sm:py-20 py-10 ${
        props.isReverse ? "sm:flex-row-reverse sm:pr-8 pr-5" : "sm:pl-[10rem] pl-0"
      } ${props.mainClassname}`}
    >
      <div
        className={`flex justify-start items-start ${props.isMobility?'items-center':"justify-center"}  ${props.isReverse?props.isMobility?'items-center':'items-start':''}  ${props.isMobility?'flex-row':"flex-col"}   flex-1  opacity-0 scale-[0.5] relative ${
          props.isReverse
            ? "pl-12 sm:pl-0 translate-x-full"
            : "-translate-x-full pl-12 sm:pl-0 sm:mt-[9.375rem]"
        } ${props.subClassName} ${props.isReverse?'':'sm:items-start sm:justify-start'}`}
      >
        {props.topContent}
        <div>
          <p className="text-[2.5rem] sm:text-[5rem] sm:leading-[6rem] leading-[3rem]  text-[#272926] font-bold">
            {props.titleTop}
          </p>
          <p className="text-[2.5rem] sm:text-[5rem] sm:leading-[6rem] leading-[3rem]  text-[#272926] font-bold sm:pb-6 pb-3">
            {props.titleBottom}
          </p>
          <p className="text-base sm:text-[2rem] sm:leading-[3rem] text-[#272926]">
            {props.contentTop}
          </p>
          <p className="text-base pb-10 sm:pb-0 sm:text-[2rem] sm:leading-[3rem] text-[#272926]">
            {props.contentBottom}
          </p>
        </div>
        {props.bottomContent}
      </div>
        <div
          className={`w-[102%] sm:w-[66.5625%] opacity-0 scale-[0.5] relative ${
            props.isReverse ? "-translate-x-full" : "translate-x-full"
          }  ${props.subClassName}`}
        >
          <img src={props.sideImg} alt="" className={`w-full h-auto ${props.isReverse ?'sm:translate-x-[-9.39%] translate-x-[-4.7%]':'sm:translate-x-[9.39%] translate-x-[4.7%]'}`}/>
          {props.tagesContent}
        </div>
    </div>
  );
};
export default CommonSection;
