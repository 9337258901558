import {FC, useRef, useState} from "react";
import {gsap} from "gsap";
import _ from 'lodash'

interface Iprops {
  fontImg?: string;
  backImg?: string;
  idName?: string;
}

const RotateImg: FC<Iprops> = ({fontImg, backImg, idName}) => {

  const timeoutId = useRef<any>()

  const handleMouseLeave = (e: any) => {
    //console.log(e.relatedTarget);
    clearTimeout(timeoutId.current)
    gsap.to(`#${idName}`, {
      delay: 0.2,
      duration: 0.6,
      rotateY: 0,
      ease: "power1.out",
    }).eventCallback('onComplete', () => {
      //console.log(`${idName} handleMouseLeave onComplete`)
    });
  };
  const handleMouseEnter = (e: any) => {
    //console.log("enter");
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      gsap.to(`#${idName}`, {
        duration: 0.6,
        rotateY: 180,
        ease: "power1.out",
      }).eventCallback('onComplete', () => {
        //console.log(`${idName} handleMouseEnter onComplete`)
      });
    }, 100)


  };

  const _handleMouseLeave = _.debounce(handleMouseLeave, 300, {leading: true})
  const _handleMouseEnter = _.debounce(handleMouseEnter, 300, {leading: true})

  return (
    <a
      onMouseEnter={_handleMouseEnter}
      onMouseLeave={_handleMouseLeave}
      className={`sm:w-[20rem] sm:h-[11.25rem] w-[6.625rem] h-[3.75rem] relative cursor-pointer`}

    >
      <div id={idName} className={'w-full h-full'} style={{transformStyle: "preserve-3d",}}>
        <img
          alt=""
          src={fontImg}
          className={`w-full h-full absolute pointer-events-none`}
          style={{backfaceVisibility: "hidden"}}
        />
        <img
          alt=""
          src={backImg}
          className={`w-full h-full absolute pointer-events-none`}
          style={{transform: "rotateY(180deg)", backfaceVisibility: "hidden"}}
        />
      </div>
    </a>
  );
};
export default RotateImg;
