export const pxToPercentage = (num: number) => {
  const base = 1920;
  return `${(num / base) * 100}%`;
};
export const transX = (num: number) => {
  const screenWidth = window.innerWidth;
  return (num / 1920) * screenWidth;
};
export const generateUniqueClassName = (index: number) => {
  const timestamp = Date.now(); // 获取当前时间戳
  return `unique-class-${timestamp}-${index}`;
};
export const isMobileOrWeb = () => {
  return window.innerWidth < 640;
};
/**
 * 获取静态图片url
 * @param imageName 图片名称 需带后缀
 */
export const getStaticImageUrl = (imageName: string): string | undefined => {
  try {
    if (process.env.NODE_ENV === "production") {
      return `https://tennispark.obs.cn-north-4.myhuaweicloud.com/static/easyclub_website/images/${imageName}`;
    } else return require(`@static/images/${imageName}`);
  } catch (error) {
    console.error(`请检查图片名：${imageName}是否拼写错误或者未添加后缀！`);
  }
};
/**
 * 获取文件资源url
 * @param fileName 文件名称 需带后缀
 */
export const getStaticFileUrl = (fileName: string): string | undefined => {
  try {
    if (process.env.NODE_ENV === "production")
    {
      return `https://tennispark.obs.cn-north-4.myhuaweicloud.com/static/easyclub_website/files/${fileName}`;
    }
    else
    return require(`@static/files/${fileName}`);
  } catch (error) {
    console.error(`请检查文件名：${fileName}是否拼写错误或者未添加后缀！`);
  }
};
export const isWeChatBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  return /micromessenger/.test(ua);
};
