import { FC, useEffect } from "react";
import { gsap } from "gsap";
import CommonSection from "@components/bussness/CommonSection";
import { getStaticImageUrl } from "@utils/index";
const Management: FC = () => {
  useEffect(() => {
  }, []);

  return (
    <CommonSection
    titleTop="数字化管理"
    titleBottom="打造好口碑"
    contentTop="管好时间、管好钱"
    contentBottom="服务口碑从全面数字化开始"
      topContent={
          <img
           alt=""
           src={getStaticImageUrl('img-price.png')}
           className="money w-[4.56rem] sm:w-[9.125rem] h-auto opacity-0 sm:mb-14 mb-7"
         />
      }
      sideImg={getStaticImageUrl('img-screen2.jpg')}
      mainClassname="section_management"
      subClassName="area_management"
      isReverse
      complete={ ()=>gsap.to(".section_management", {
        onComplete: () => {
          gsap.fromTo(".money", 
            {
             x:'-17rem',opacity:0
            },
            {
            x:0,
            rotation:360,
            duration: 0.5,
            opacity: 1,
            ease: "power1.out",
            scrollTrigger: {
              trigger: ".section_management",
              start: "top bottom", // 定义触发动画的位置
              end: "bottom top", // 定义动画结束的位置
              toggleActions: "play none none none",
            },
          });
        },
      })}
    />
  );
};
export default Management;
