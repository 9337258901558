import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from '@pages/Home';
import {createContext, useEffect, useState} from "react";
import {useUpdateEffect} from "ahooks";

export const MyContext = createContext<Record<string, any>>({})

const App = () => {
  //const maxWidth=window.innerHeight/1080*1920;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  //const [isBigPC, setisBigPC] = useState(window.innerWidth >maxWidth);

  const handleResize = () => {
    const width = window.innerWidth;
    const baseFontSize = 16; // 基准字号，根据设计稿定
    const scale = width >= 640 ? width / 1920 : width / 390; // 假设375px为设计稿宽度
    document.documentElement.style.fontSize = baseFontSize * scale + 'px';
    setIsMobile(width < 640);
    //setisBigPC(width>maxWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', () => {
      handleResize()
    })
  }, []);

  useUpdateEffect(() => {
    window.location.reload()
  }, [isMobile]);

  return (
    <MyContext.Provider value={{isMobile}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </MyContext.Provider>
  );
}

export default App;
